import React, { useEffect, useState } from 'react'
import LandingHeader from '../LandingComponents/LandingHeader'
import LandingBanner from '../LandingComponents/LandingBanner'
import loginImg from '../Images/loginImg.png'
import { Link, useParams } from 'react-router-dom'
import LandingFooter from '../LandingComponents/LandingFooter'
import innerBg from '../Images/login-banner.jpg'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { FaEye } from 'react-icons/fa'
import axios from 'axios'
import { encryptData, urlSafeBase64Encode } from '../Helper/encrypt'
import Loader from '../Components/Loader'
import { toast } from 'react-toastify'

export default function Signup() {
    const [inviteCode, setInviteCode] = useState("")
    const [showLoader, setShowLoader] = useState(false)

    useEffect(() => {
        const url = new URL(window.location.href)
        const params = new URLSearchParams(url.search)
        const invite = params.get('invite')
        setInviteCode(invite)
        formik.setFieldValue('sponserId', invite)
    }, [])


    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
            rePassword: "",
            termNCondition: false,
            sponserId: inviteCode
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email('Invalid email format')
                .required('Email is required'),
            password: Yup.string()
                .required('Password is required'),
            rePassword: Yup.string()
                .oneOf([Yup.ref('password'), null], 'Passwords must match')
                .required('Please confirm your password'),
            termNCondition: Yup.boolean()
                .oneOf([true], 'You must accept the terms and conditions')
                .required('Please accept the terms and conditions'),
        }),
        onSubmit: values => {
            setShowLoader(true)
            let obj = {
                email: values.email,
                password: values.password,
                sponserId: values.sponserId ? values.sponserId : "",
                origin: window.location.origin
            }
            axios.post(process.env.REACT_APP_API_URL + '/add-unverified-user', { data: encryptData(obj) })
                .then((res) => {
                    if (res.status == 200) {
                        toast.success(res.data.message)
                    }
                    if (res.status == 250) {
                        toast.error(res.data.error)
                    }
                    setShowLoader(false)
                    formik.resetForm()
                })
        }
    })

    return (
        <>
            {showLoader ? <Loader /> : ""}
            <LandingHeader />
            <div className="inner-banner login-banner section-bg overflow-hidden" style={{ backgroundImage: `url(${innerBg})`, backgroundSize: "cover", backgroundPosition: "center" }}>
                <div className="container">
                    <div className="inner__banner__content row justify-content-center">
                        <div className="col-lg-6 col-xl-5">
                            <div className="account__form__wrapper">
                                <h3 className="title text-center text-white">Create Your Account</h3>
                                <form className="form account__form" onSubmit={formik.handleSubmit}>
                                    <div className="form-group">
                                        <input type="email" className="form-control form--control" name='email' id='email' onChange={formik.handleChange} value={formik.values.email} placeholder="Email*" />
                                        {formik.errors.email && formik.submitCount > 0 ? (
                                            <span className="text-danger error-message">{formik.errors.email}</span>
                                        ) : null}
                                    </div>
                                    <div className="form-group">
                                        <input type="password" className="form-control form--control" name='password' id='password' value={formik.values.password} onChange={formik.handleChange} placeholder="Password*" />
                                        {formik.errors.password && formik.submitCount > 0 ? (
                                            <span className="text-danger error-message">{formik.errors.password}</span>
                                        ) : null}
                                    </div>
                                    <div className="form-group">
                                        <input type="password" className="form-control form--control" value={formik.values.rePassword} name='rePassword' id='rePassword' onChange={formik.handleChange} placeholder="Re-Password*" />
                                        {formik.errors.rePassword && formik.submitCount > 0 ? (
                                            <span className="text-danger error-message">{formik.errors.rePassword}</span>
                                        ) : null}
                                    </div>
                                    <div className="form-group">
                                        <input type="text" className="form-control form--control" value={formik.values.sponserId} name='sponserId' id='sponserId' onChange={formik.handleChange} placeholder="Invitation Code" />
                                        {formik.errors.sponserId && formik.submitCount > 0 ? (
                                            <span className="text-danger error-message">{formik.errors.sponserId}</span>
                                        ) : null}
                                    </div>
                                    <div className="form--check me-4">
                                        <input type="checkbox" value={formik.values.termNCondition} name='termNCondition' id='termNCondition' onChange={formik.handleChange} />
                                        <label for="rem-me" className='text-white'>I agree with all <span className="text-white ms-2">Terms & Condition</span></label>
                                    </div>
                                    {formik.errors.termNCondition && formik.submitCount > 0 ? (
                                        <span className="text-danger error-message">{formik.errors.termNCondition}</span>
                                    ) : null}
                                    <div className="text-center">
                                        <button className="btn cmn--btn mt-4" type='submit'>Sign Up Account</button>
                                    </div>
                                </form>
                                <p className="mt-4 text-center text-white">Already you have an account in here? <Link className="ms-2 text--secondary" to={'/sign-in'}>Sign In</Link></p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="shapes">
                    <img src={innerBg} alt="banner" className="shape shape1" />
                </div> */}
            </div>
            <LandingFooter full={true} />
        </>
    )
}
