import faqBg from '../Images/faqBg.png'
import React, { useState } from 'react'

export default function LandingFaq() {

    const [openIndex, setOpenIndex] = useState(3);

    const handleToggle = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };
    return (
        <section className="faq-section padding-top padding-bottom bg_img" style={{ background: `url(${faqBg})` }}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-7 col-md-10">
                        <div className="section__header text-center max-p">
                            <h2 className="section__header-title">Frequently Asked Questions</h2>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    {/* <ul className="nav-tabs nav faq__tab__menu transection__tab__menu border-0 justify-content-center">
                            <li><a data-bs-toggle="tab" href="#Earning" className="cmn--btn2 active">Earning</a></li>
                            <li><a data-bs-toggle="tab" href="#deposit" className="cmn--btn2">Deposit</a></li>
                            <li><a data-bs-toggle="tab" href="#withdraw" className="cmn--btn2">Withdraw</a></li>
                        </ul> */}
                    <div className="tab-content">

                        <div className="tab-pane show fade active" id="Earning">
                            <div className="row justify-content-center">
                                <div className="col-xl-8 col-lg-10">
                                    <div className="faq__wrapper">
                                        <div className={`faq__item ${openIndex === 1 ? 'open active' : ''}`} onClick={() => handleToggle(1)}>
                                            <div className="faq__item-title">
                                                <h4 className="title">Why You should become an Investor?</h4>
                                            </div>
                                            {openIndex === 1 && (
                                                <div className="faq__item-content">
                                                    <p>Fringilla mauris amet nibh. Donec sodales sagittis magna. Sed consequat, leo eget bibendum sodales augue velit cursus nunc quis gravida magna mi a libero. Fusce vulputate eleifend sapien. Vestibulum purus quam, scelerisque ut, mollis sed, nonummy idmetus</p>
                                                </div>
                                            )}
                                        </div>

                                        <div className={`faq__item ${openIndex === 2 ? 'open active' : ''}`} onClick={() => handleToggle(2)}>
                                            <div className="faq__item-title">
                                                <h4 className="title">Why You Choose Us?</h4>
                                            </div>
                                            {openIndex === 2 && (
                                                <div className="faq__item-content">
                                                    <p>Fringilla mauris amet nibh. Donec sodales sagittis magna. Sed consequat, leo eget bibendum sodales augue velit cursus nunc quis gravida magna mi a libero. Fusce vulputate eleifend sapien. Vestibulum purus quam, scelerisque ut, mollis sed, nonummy idmetus</p>
                                                </div>
                                            )}
                                        </div>

                                        <div className={`faq__item ${openIndex === 3 ? 'open active' : ''}`} onClick={() => handleToggle(3)}>
                                            <div className="faq__item-title">
                                                <h4 className="title">What is Hyip Earning?</h4>
                                            </div>
                                            {openIndex === 3 && (
                                                <div className="faq__item-content open">
                                                    <p>Fringilla mauris amet nibh. Donec sodales sagittis magna. Sed consequat, leo eget bibendum sodales augue velit cursus nunc quis gravida magna mi a libero. Fusce vulputate eleifend sapien. Vestibulum purus quam, scelerisque ut, mollis sed, nonummy idmetus</p>
                                                </div>
                                            )}
                                        </div>

                                        <div className={`faq__item ${openIndex === 4 ? 'open active' : ''}`} onClick={() => handleToggle(4)}>
                                            <div className="faq__item-title">
                                                <h4 className="title">How to do we work?</h4>
                                            </div>
                                            {openIndex === 4 && (
                                                <div className="faq__item-content">
                                                    <p>Fringilla mauris amet nibh. Donec sodales sagittis magna. Sed consequat, leo eget bibendum sodales augue velit cursus nunc quis gravida magna mi a libero. Fusce vulputate eleifend sapien. Vestibulum purus quam, scelerisque ut, mollis sed, nonummy idmetus</p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    )
}
