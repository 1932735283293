import React, { useEffect, useState } from 'react';
import UserSidebar from '../Components/UserSidebar';
import { isMobile } from 'react-device-detect';
import MUIDataTable from 'mui-datatables';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import axios from 'axios';
import { encryptData } from '../Helper/encrypt';
import { toast } from 'react-toastify';
import moment from 'moment';
import Loader from '../Components/Loader';
import { useConnex, useWallet } from '@vechain/dapp-kit-react';
import { makeid, numberid } from '../Helper/helper';
import { BN } from 'bn.js';
import { FaCheck } from 'react-icons/fa'
import { Button, Modal, Spinner } from 'react-bootstrap';

export default function Package() {
    const [data, setData] = useState([]);
    const [withdrawData, setWithdrawData] = useState([])
    const [amount, setAmount] = useState(0);
    const [tab, setTab] = useState(0)
    const [showLoader, setShowLoader] = useState(true);
    const [userData, setUserData] = useState([])
    const [withdrawAmount, setWithdrawAmount] = useState(0)
    const [vetPrice, setVetPrice] = useState(0.06)
    const MINIMUM_WITHDRAW_AMOUNT = 10
    const [otp, setOTP] = useState(new Array(6).fill(''))
    const [actualOTP, setActualOTP] = useState("")
    const { account } = useWallet()
    const connex = useConnex()
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const RECIPIENT_ADDRESS = "0xAE0eE4789177de88166d33941a8f5774E1810fdD"

    // Fetch deposit data on component mount
    useEffect(() => {
        getDeposit();
        getWithdraw();
        getVetPrice()
        getUserData()
    }, []);

    const getVetPrice = () => {
        axios.get("https://api.coingecko.com/api/v3/simple/price?ids=vechain&vs_currencies=usd")
            .then((res) => {
                if (res.status == 200) {
                    setVetPrice(res.data.vechain.usd)
                }
            })
    }

    const getUserData = () => {
        axios.post(process.env.REACT_APP_API_URL + "/user/get-user-data", { id: localStorage.getItem('mongoid') })
            .then((res) => {
                if (res.status == 200) {
                    setUserData(res.data.data)
                }
            })
    }

    // Fetch user deposit history
    const getDeposit = () => {
        axios.post(`${process.env.REACT_APP_API_URL}/package/get-user-deposit`, { id: localStorage.getItem('mongoid') })
            .then((res) => {
                setShowLoader(false);
                if (res.status === 200) {
                    console.log(res.data)
                    setData(res?.data);  // Update table data
                }
            })
            .catch((err) => {
                toast.error("Failed to fetch deposit history.");
                setShowLoader(false);
            });
    };

    const getWithdraw = () => {
        axios.post(`${process.env.REACT_APP_API_URL}/package/get-user-withdraw`, { id: localStorage.getItem('mongoid') })
            .then((res) => {
                setShowLoader(false);
                if (res.status === 200) {
                    setWithdrawData(res?.data);  // Update table data
                }
            })
            .catch((err) => {
                toast.error("Failed to fetch withdraw history.");
                setShowLoader(false);
            });
    };

    // Custom theme for MUI Table
    const getMuiTheme = () => createTheme({
        components: {
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        backgroundColor: '#28008C', // Background color for table cells
                        color: '#c5c7cc',
                        borderBottom: '2px solid #595a5d' // Custom border color
                    }
                }
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        backgroundColor: '#28008C', // Background color for the table container
                        color: '#c5c7cc'
                    }
                }
            }
        }
    });

    const columns = [
        {
            name: 'date',
            label: 'Date',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => (
                    <p>{moment(value).format('DD-MM-YYYY')}</p>
                )
            }
        },
        {
            name: 'txHash',
            label: 'Transaction Hash',
            options: {
                filter: true,
                sort: false
            }
        },
        {
            name: 'currentBalance',
            label: 'Starting Balance',
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value) => (
                    <p>{Number(value).toFixed(2)}</p>
                )
            }
        },
        {
            name: 'amount',
            label: 'Amount',
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value) => (
                    <p>{Number(value).toFixed(2)}</p>
                )
            }
        },
        {
            name: 'isPending',
            label: 'Status',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => (
                    <p className='text-start ms-5'>{value ?
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                        : <FaCheck />}</p>
                )
            }
        },
    ];

    // Options to hide various features from the table
    const options = {
        filter: false,         // Hide filter options
        download: false,       // Hide download button
        print: false,          // Hide print button
        viewColumns: false,    // Hide view columns button
        search: false,         // Hide search box
        selectableRows: 'none',
        responsive: 'standard',
        pagination: false,     // Hide pagination if you want
        elevation: 0           // Remove shadow
    };

    const sendTx = async (amountInVet) => {
        const amountInWei = new BN(amountInVet).mul(new BN(10).pow(new BN(18))).toString();
        console.log(amountInWei, amountInVet)
        if (!account || !RECIPIENT_ADDRESS) { return }
        const comment = `0x${Buffer.from(numberid(10), 'utf-8').toString('hex')}`;
        try {
            let obj = {
                userid: localStorage.getItem("mongoid"),
                pkgAmount: amount,
                nonce: comment,
                walletAddress: account,
                tokenAmount: amountInVet
            }
            axios.post(process.env.REACT_APP_API_URL + '/package/add-pending-transaction', { data: encryptData(obj) })
                .then(async (res) => {
                    if (res.status == 200) {
                        const clauses = [
                            {
                                to: RECIPIENT_ADDRESS,
                                value: amountInWei,
                                data: comment,
                            }
                        ]

                        const tx = connex.vendor.sign("tx", clauses).signer(account)

                        const txid = await tx.request()
                        let body = {
                            nonce: comment,
                            hash: await txid.txid
                        }
                        console.log("here")
                        await axios.post(process.env.REACT_APP_API_URL + '/package/update-hash', { data: encryptData(body) })
                            .then((res) => {
                                console.log("then")
                                if (res.status == 200) {
                                    setShowLoader(false)
                                    toast.success("Your package will be activated upon successful verification.")
                                    getUserData()
                                    getDeposit()
                                }
                            })
                    }
                })







        } catch (error) {
            setShowLoader(false)
            console.log(error)
        }
    }

    const handleChange = (e, index) => {
        const value = e.target.value;

        // Allow only numeric values
        if (/[^0-9]/.test(value)) return;

        const newOtp = [...otp];
        newOtp[index] = value;

        setOTP(newOtp);

        // Move focus to the next input if current value is filled
        if (value && index < otp.length - 1) {
            document.getElementById(`otp-input-${index + 1}`).focus();
        }
    };


    // Add new package function
    const addPackage = async () => {
        // await getVetPrice();
        const amountInVet = amount / vetPrice
        if (account) {
            if (await checkWallet()) {
                setShowLoader(true);
                if ((amount >= 50 && amount % 50 == 0)) {
                    // const obj = {
                    //     userid: localStorage.getItem('mongoid'),
                    //     pkgAmount: amount
                    // };
                    // axios.post(`${process.env.REACT_APP_API_URL}/package/add-package`, { data: encryptData(obj) })
                    //     .then((res) => {
                    //         if (res.status === 200) {
                    //             toast.success('Package added successfully.');
                    //             getDeposit();  // Refresh deposit history
                    //             getUserData()
                    //         } else {
                    //             toast.error('Failed to add package.');
                    //         }
                    //     })
                    //     .catch(() => {
                    //         toast.error('Error occurred while adding package.');
                    //     })
                    //     .finally(() => {
                    //         setShowLoader(false);
                    //     });
                    sendTx(amountInVet)
                } else {
                    setShowLoader(false)
                    toast.warning('Please enter a valid amount. or in multiply by 50');
                }

            } else {
                toast.error("Insufficient funds!")
            }
        } else {
            toast.error("Please connect your wallet.")
        }
    };

    // const checkWallet = async () => {
    //     const amountInVet = amount / vetPrice
    //     let walletAmount;
    //     await axios.get(`https://explore-mainnet.veblocks.net/accounts/${account}`)
    //         .then((res) => {
    //             if (res.status == 200) {
    //                 walletAmount = (Number(hexToDecimal(res.data.balance)) / 10 ** 18)
    //             }
    //         })
    //     if (walletAmount >= amountInVet) {
    //         return true
    //     } else {
    //         return false
    //     }
    // }

    const checkWallet = async () => {
        const amountInVet = amount / vetPrice;
        let walletAmount;

        await axios.get(`${process.env.REACT_APP_API_URL}/proxy/accounts/${account}`) // Call your proxy instead
            .then((res) => {
                if (res.status === 200) {
                    walletAmount = Number(hexToDecimal(res.data.balance)) / 10 ** 18;
                }
            });

        return walletAmount >= amountInVet;
    };


    const hexToDecimal = (hex) => {
        return parseInt(hex, 16);
    }

    const sendOTP = () => {
        if (withdrawAmount >= MINIMUM_WITHDRAW_AMOUNT && withdrawAmount <= userData?.balance && account) {
            let number = numberid(6)
            setActualOTP(number)
            setShowLoader(true)

            let obj = {
                gmail: localStorage.getItem("email"),
                otp: number
            }
            axios.post(process.env.REACT_APP_API_URL + '/package/send-withdraw-otp', { data: encryptData(obj) })
                .then((res) => {
                    if (res.status == 200) {
                        setShowLoader(false)
                        handleShow()
                    }
                })
        } else {
            toast.error("something went wrong!")
        }
    }

    const verifyOTP = () => {
        if (Number(actualOTP) == Number(otp.join(""))) {
            withdrawBalance()
        }
    }

    const withdrawBalance = () => {
        setShowLoader(true)
        let obj = {
            userid: localStorage.getItem('mongoid'),
            amount: withdrawAmount
        }
        axios.post(process.env.REACT_APP_API_URL + '/package/withdraw-balance', { data: encryptData(obj) })
            .then((res) => {
                if (res.status == 200) {

                }
            })
        setTimeout(() => {
            getUserData();
            getWithdraw()
            toast.success("Your withdrawal has been successfully completed.")
            setShowLoader(false)
            handleClose()
            setOTP(new Array(6).fill(""))
        }, 5000);

    }

    return (
        <>
            {showLoader ? <Loader /> : ""}
            <UserSidebar />
            <div className={isMobile ? 'MobileScreen' : 'BigScreen'} id="page-container">
                <div className="container mt-3">
                    <div className="top-nav">
                        <div className="top-nav">
                            <div onClick={() => setTab(0)} className={tab == 0 ? "cursor-pointer top-nav-item top-active text-white" : 'cursor-pointer top-nav-item text-white'}>DEPOSIT</div>
                            <div onClick={() => setTab(1)} className={tab == 1 ? "cursor-pointer top-nav-item top-active text-white" : 'cursor-pointer top-nav-item text-white'}>WITHDRAW</div>
                        </div>
                    </div>
                    {tab == 0 ?
                        <>
                            <div className="deposit-box mt-3">
                                <div className="box-heading">
                                    <h4>Deposit</h4>
                                </div>
                                <hr />
                                <div className="row p-3 text-white">
                                    <div className="col-md-6 mt-3">
                                        Wallet Address: <span className="word-break">{account}</span>
                                    </div>
                                    <div className="col-md-6 mt-3">
                                        Deposit Balance: {Number(userData?.Deposit).toFixed(2)}
                                    </div>
                                    <div className="col-md-6 mt-3">
                                        Deposit Amount:
                                        <input
                                            type="text"
                                            className="form--control w-100 ps-3"
                                            value={amount}
                                            onChange={(e) => setAmount(e.target.value)}
                                            placeholder="Enter Deposit Amount"
                                        />
                                    </div>
                                    <div className="col-md-6 mt-3">
                                        VET amount :
                                        <input
                                            type="text"
                                            className="form--control w-100 ps-3 text-white"
                                            value={Number(amount / vetPrice).toFixed(2)}
                                            onChange={(e) => setAmount(e.target.value)}
                                            placeholder="Enter Deposit Amount"
                                            disabled
                                        />

                                    </div>
                                </div>
                                <div className="button__wrapper">
                                    <a className="cmn--btn mt-3 mb-3 ms-3" onClick={addPackage}>Submit</a>
                                </div>
                            </div>
                            <div className="deposit-history mt-3">
                                <ThemeProvider theme={getMuiTheme()}>
                                    <MUIDataTable
                                        title={"Deposit History"}
                                        data={data}
                                        columns={columns}
                                        options={options}
                                    />
                                </ThemeProvider>
                            </div>
                        </>
                        :
                        <>
                            <div className="deposit-box mt-3">
                                <div className="box-heading">
                                    <h4>Withdraw</h4>
                                </div>
                                <hr />
                                <div className="row p-3 text-white">
                                    <div className="col-md-6 mt-3">
                                        Wallet Address: <span className="word-break">{account}</span>
                                    </div>
                                    <div className="col-md-6 mt-3">
                                        Withdrawable Balance: {Number(userData?.balance).toFixed(2)}
                                    </div>
                                    <div className="col-md-6 mt-3">
                                        Withdraw Amount:
                                        <input
                                            type="text"
                                            className="form--control w-100 ps-3"
                                            value={withdrawAmount}
                                            onChange={(e) => setWithdrawAmount(e.target.value)}
                                            placeholder="Enter Withdraw Amount"
                                        />
                                    </div>
                                </div>
                                <div className="button__wrapper">
                                    <a className="cmn--btn mt-3 mb-3 ms-3" onClick={sendOTP}>Submit</a>
                                </div>
                            </div>
                            <div className="deposit-history mt-3">
                                <ThemeProvider theme={getMuiTheme()}>
                                    <MUIDataTable
                                        title={"Withdraw History"}
                                        data={withdrawData}
                                        columns={columns}
                                        options={options}
                                    />
                                </ThemeProvider>
                            </div>
                        </>
                    }
                </div>
            </div>
            <Modal show={show} centered>
                <Modal.Body>
                    <h3 className="text-center text-black mt-3">Verify Your OTP</h3>
                    <p className='mt-4 text-center text-black'>Enter the 6-digit OTP sent to your email.</p>
                    <div className="otp-inputs mx-5 mt-3">
                        {otp.map((value, index) => (
                            <input
                                key={index}
                                id={`otp-input-${index}`}
                                type="text"
                                maxLength={1}
                                value={value}
                                onChange={(e) => handleChange(e, index)}
                                className="otp-input"
                                autoFocus={index === 0} // Auto-focus on the first input
                            />
                        ))}
                    </div>
                    <div className="mx-4 mt-4">
                        <button className="verify-btn mb-4" onClick={verifyOTP}>Verify OTP</button>
                        {/* <p className='text-center text-black mt-3 mb-4'>Didn’t receive the code? <a className='text-primary cursor-pointer'>Resend OTP</a></p> */}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}
