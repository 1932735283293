import React, { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import UserSidebar from '../Components/UserSidebar'
import { MdAttachMoney, MdOutlineCalendarToday, MdOutlineContentCopy, MdOutlinePendingActions } from 'react-icons/md'
import { FaStar, FaUsers } from 'react-icons/fa'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import Loader from '../Components/Loader'
import { PiHandDeposit } from 'react-icons/pi'
import { TbAffiliate, TbMoneybag } from "react-icons/tb";
import { FaRankingStar } from "react-icons/fa6";
import { GiMoneyStack, GiTakeMyMoney } from 'react-icons/gi'
import { CiMoneyCheck1 } from "react-icons/ci";
import { useWallet } from '@vechain/dapp-kit-react'
export default function Dashboard() {

    const [userData, setUserData] = useState([])
    const [showLoader, setShowLoader] = useState(true)
    const { account } = useWallet()
    const [pendingPkgAmount, setPendingPkgAmount] = useState(0)
    const [todayEarning, setTodayEarning] = useState(0)

    useEffect(() => {
        getUserData()
        getTodayEarning()
        getPendingPkgAmount()
        if (account) {
            axios.post(process.env.REACT_APP_API_URL + '/user/change-wallet', { userid: localStorage.getItem('mongoid'), wallet: account })
        }
    }, [])

    const getPendingPkgAmount = () => {
        axios.post(process.env.REACT_APP_API_URL + '/package/get-package-pending-amount', { userid: localStorage.getItem('mongoid') })
            .then((res) => {
                if (res.status == 200) {
                    if (res.data.amount) {
                        setPendingPkgAmount(res.data?.amount)
                        console.log(res.data)
                    }
                    console.log(res.data)
                }
            })
    }

    const getTodayEarning = () => {
        axios.post(process.env.REACT_APP_API_URL + '/user/return-today-earning', { userid: localStorage.getItem('mongoid') })
            .then((res) => {
                if (res.status == 200) {
                    setTodayEarning(res.data.amount)
                }
            })
    }


    const getUserData = () => {
        axios.post(process.env.REACT_APP_API_URL + '/user/get-user-data', { id: localStorage.getItem('mongoid') })
            .then((res) => {
                if (res.status == 200) {
                    setShowLoader(false)
                    console.log(res.data.data)
                    setUserData(res.data.data)
                }
            })
    }

    const navigate = useNavigate()

    const copy = (text) => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(text)
                .then(() => {
                    toast.success("Link copied successfully.")
                    // You can also display a success message here if needed
                })
                .catch((err) => {
                    console.error('Failed to copy text: ', err);
                });
        } else {
            // Fallback for older browsers
            let textarea = document.createElement('textarea');
            textarea.value = text;
            textarea.style.position = 'fixed'; // Prevent scrolling to the bottom
            document.body.appendChild(textarea);
            textarea.focus();
            textarea.select();
            try {
                document.execCommand('copy');
                // console.log('Text copied to clipboard (fallback):', text);
                toast.success("Link copied successfully.")
            } catch (err) {
                console.error('Fallback: Oops, unable to copy', err);
            }
            document.body.removeChild(textarea);
        }

    }

    return (
        <>
            {showLoader ? <Loader /> : ""}
            <UserSidebar />
            <div className={isMobile ? "MobileScreen" : "BigScreen"} id="page-container">
                <div className="container mt-5">
                    <div className="row">
                        <div className="col-md-3 mt-3">
                            <div className="dashboard-btn" onClick={() => navigate('/package')}>
                                <span className="icon" style={{ backgroundColor: "#034fab" }}>
                                    <MdAttachMoney style={{ fontSize: 28 }} />
                                </span>
                                <span className="heading">
                                    Package
                                </span>
                            </div>
                        </div>
                        <div className="col-md-3 mt-3">
                            <div className="dashboard-btn" onClick={() => navigate('/my-team')}>
                                <span className="icon" style={{ backgroundColor: "#82BE00", color: "#000" }}>
                                    <FaUsers style={{ fontSize: 28 }} />
                                </span>
                                <span className="heading">
                                    Affiliate
                                </span>
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            {userData?.Deposit > 0 ? <div className="dashboard-btn" onClick={() => copy(window.location.origin + "/sign-up?invite=" + localStorage.getItem('userid'))}>
                                <span className="icon" style={{ backgroundColor: "#01b0c5", color: "#000" }}>
                                    <MdOutlineContentCopy style={{ fontSize: 28 }} />
                                </span>
                                <span className="heading">
                                    {window.location.origin + "/sign-up?invite=" + localStorage.getItem('userid')}
                                </span>
                            </div> : ""}
                        </div>
                    </div>
                    <div className="row mt-3 pb-5">
                        <div className="col-md-4 mt-3">
                            <div className="dashboard-box">
                                <h3><PiHandDeposit style={{ fontSize: 70, borderRadius: "50%", backgroundColor: "#010124", padding: 10 }} /> </h3>
                                {/* <hr /> */}
                                <h4 className='mt-3'>${Number(userData?.Deposit).toFixed(2)}</h4>
                                <h4>Current Deposit</h4>
                                {/* <p>Total</p> */}
                            </div>
                        </div>
                        <div className="col-md-4 mt-3">
                            <div className="dashboard-box">
                                <h3><MdOutlinePendingActions style={{ fontSize: 70, borderRadius: "50%", backgroundColor: "#010124", padding: 10 }} /> </h3>
                                {/* <hr /> */}
                                <h4 className='mt-3'>${pendingPkgAmount ? Number(pendingPkgAmount).toFixed(2) : 0.00}</h4>
                                <h4>Pending Reward</h4>
                                {/* <p>Total</p> */}
                            </div>
                        </div>
                        <div className="col-md-4 mt-3">
                            <div className="dashboard-box">
                                <h3><TbAffiliate style={{ fontSize: 70, borderRadius: "50%", backgroundColor: "#010124", padding: 10 }} /> </h3>
                                <h4 className='mt-3'>${Number(userData?.affiliateEarn).toFixed(2)}</h4>
                                <h4>Affiliate Earn</h4>
                                {/* <p>Total</p> */}
                            </div>
                        </div>
                        <div className="col-md-4 mt-3">
                            <div className="dashboard-box">
                                <h3><MdOutlineCalendarToday style={{ fontSize: 70, borderRadius: "50%", backgroundColor: "#010124", padding: 10 }} /> </h3>

                                <h4 className='mt-3'>${Number(todayEarning).toFixed(2)}</h4>
                                <h4>Today Earning</h4>
                                {/* <p>Total</p> */}
                            </div>
                        </div>

                        <div className="col-md-4 mt-3">
                            <div className="dashboard-box">
                                <h3><GiTakeMyMoney style={{ fontSize: 70, borderRadius: "50%", backgroundColor: "#010124", padding: 10 }} /> </h3>
                                <h4 className='mt-3'>${Number(userData?.totalWithdraw).toFixed(2)}</h4>
                                <h4>Total Withdrawal</h4>
                                {/* <p>Total</p> */}
                            </div>
                        </div>
                        <div className="col-md-4 mt-3">
                            <div className="dashboard-box">
                                <h3><TbMoneybag style={{ fontSize: 70, borderRadius: "50%", backgroundColor: "#010124", padding: 10 }} /> </h3>

                                <h4 className='mt-3'>${Number(userData?.allTimeDeposit).toFixed(2)}</h4>
                                <h4>Total Deposit</h4>
                                {/* <p>Total</p> */}
                            </div>
                        </div>
                        <div className="col-md-4 mt-3">
                            <div className="dashboard-box">
                                <h3><FaRankingStar style={{ fontSize: 70, borderRadius: "50%", backgroundColor: "#010124", padding: 0 }} /> </h3>
                                <h4 className='mt-3'>${Number(userData?.rankRewardRecived).toFixed(2)}</h4>
                                <h4>Rank Income</h4>
                                {/* <p>Total</p> */}
                            </div>
                        </div>
                        <div className="col-md-4 mt-3">
                            <div className="dashboard-box">
                                <h3><GiMoneyStack style={{ fontSize: 70, borderRadius: "50%", backgroundColor: "#010124", padding: 10 }} /> </h3>
                                <h4 className='mt-3'>${Number(userData?.totalEarn).toFixed(2)}</h4>
                                <h4>Total Earning</h4>
                                {/* <p>Total</p> */}
                            </div>
                        </div>
                        <div className="col-md-4 mt-3">
                            <div className="dashboard-box">
                                <h3><CiMoneyCheck1 style={{ fontSize: 70, borderRadius: "50%", backgroundColor: "#010124", padding: 10 }} /> </h3>

                                <h4 className='mt-3'>${Number(userData?.totalTeamDeposit).toFixed(2)}</h4>
                                <h4>Team Business</h4>
                                {/* <p>Total</p> */}
                            </div>
                        </div>
                        <div className="col-md-4 mt-3">
                            <div className="dashboard-box">
                                <h3><FaRankingStar style={{ fontSize: 70, borderRadius: "50%", backgroundColor: "#010124", padding: 0 }} /> </h3>
                                <h4 className='mt-3'> <span className='mt-1'>
                                    {userData?.rank}
                                </span> <FaStar style={{ marginTop: "-5px" }} /></h4>
                                <h4>Current Rank</h4>
                                {/* <p>Total</p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
