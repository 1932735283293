import React, { useState } from 'react'
import LandingHeader from '../LandingComponents/LandingHeader'
import LandingBanner from '../LandingComponents/LandingBanner'
import LandingFooter from '../LandingComponents/LandingFooter'
import loginImg from '../Images/loginImg.png'
import { Link, useNavigate } from 'react-router-dom'
import innerBg from '../Images/login-banner.jpg'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import { toast } from 'react-toastify'
import Loader from '../Components/Loader'
import { getDeviceId } from '../Helper/helper'

export default function Signin() {
    const [showLoader, setShowLoader] = useState(false)
    const navigate = useNavigate()

    const formik = useFormik({
        initialValues: {
            email: "",
            password: ""
        },
        validationSchema: Yup.object({
            email: Yup.string().email("Email is not valid").required("Email is required"),
            password: Yup.string().required("Password is required")
        }),
        onSubmit: async (values) => {
            setShowLoader(true)
            const deviceId = getDeviceId()
            axios.post(process.env.REACT_APP_API_URL + '/login-user', { email: values.email, password: values.password, origin: window.location.origin, deviceId: deviceId })
                .then((res) => {
                    setShowLoader(false)
                    if (res.data.status == 200) {
                        console.log(res)
                        const { sessionToken, expiresAt } = res.data.token;
                        localStorage.setItem("email", res.data.data.email)
                        localStorage.setItem("userid", res.data.data.id)
                        localStorage.setItem("mongoid", res.data.data._id)
                        localStorage.setItem('sessionToken', sessionToken);
                        localStorage.setItem('expiresAt', expiresAt);
                        toast.success(res.data.message)
                        navigate('/dashboard')
                    } else if (res.data.status == 401) {
                        toast.warning(res.data.message)
                    }
                })
        }
    })


    return (
        <>
            {showLoader ? <Loader /> : ""}
            <LandingHeader />
            <div className="inner-banner login-banner section-bg overflow-hidden" style={{ backgroundImage: `url(${innerBg})`, backgroundSize: "cover", backgroundPosition: "center" }}>
                <div className="container">
                    <div className="inner__banner__content row justify-content-center">
                        <div className="col-lg-6 col-xl-5">
                            <div className="account__form__wrapper">
                                <h3 className="title text-center text-white">Hello, Welcome Back</h3>
                                <form className="form account__form" onSubmit={formik.handleSubmit}>
                                    <div className="form-group">
                                        <input type="email" name='email' id='email' value={formik.values.email} onChange={formik.handleChange} className="form-control form--control" placeholder="Email" />
                                        {formik.errors.email && formik.submitCount > 0 ? (
                                            <div className="text-danger">{formik.errors.email}</div>
                                        ) : ""}
                                    </div>
                                    <div className="form-group">
                                        <input type="password" name='password' id='password' value={formik.values.password} onChange={formik.handleChange} className="form-control form--control" placeholder="Password" />
                                        {formik.errors.password && formik.submitCount > 0 ? (
                                            <div className="text-danger">{formik.errors.password}</div>
                                        ) : ""}
                                        {/* <span className="eye-icon"><i className="las la-eye"></i></span> */}
                                    </div>
                                    <div className=" d-flex flex-wrap align-items-center">
                                        {/* <div className="form--check me-4">
                                            <input type="checkbox" id="rem-me" />
                                            <label for="rem-me">Remember Me</label>
                                        </div> */}
                                        {/* <Link to={'/forgot-password'} className="forgot-pass text--base">Forgot Password</Link> */}
                                    </div>
                                    <div className="text-center">
                                        <button className="btn cmn--btn mt-4" type='submit'>Sign In Account</button>
                                    </div>
                                </form>

                                <p className="mt-4 text-center text-white">Don't have on Account yet? <Link className="ms-2 text--secondary" to={'/sign-up'}>Create Account</Link></p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="shapes">
                    <img src={innerBg} alt="banner" className="shape shape1" />
                </div> */}
            </div>
            <LandingFooter full={true} />
        </>
    )
}
