import React from 'react'
import LandingHeader from '../LandingComponents/LandingHeader'
import LandingBanner from '../LandingComponents/LandingBanner'
import { Link } from 'react-router-dom'
import { LiaCoinsSolid, LiaHeadphonesAltSolid, LiaShieldAltSolid, LiaUserCheckSolid } from 'react-icons/lia'
import LandingPlan from '../LandingComponents/LandingPlan'
import LandingInvestor from '../LandingComponents/LandingInvestor'
import OurApp from '../LandingComponents/OurApp'
import LandingFooter from '../LandingComponents/LandingFooter'

export default function About() {
    return (
        <>
            <LandingHeader />
            <LandingBanner page={"About"} header={"About Company"} />
            <section className="feature-section padding-bottom">
                <div className="container">
                    <div className="row gy-5">
                        <div className="col-lg-4">
                            <div className="section__header m-0">
                                <h2 className="section__header-title">Let`s Go for Earn More Profit</h2>
                                <span className="d-block">
                                    Vechain network Management is the Financial Planning and
                                    Earning company. As an independent wealth
                                    management firm, Vechain network Management helps
                                    individuals and families pursue their financial goals and
                                    lifestyle objectives through comprehensive financial
                                    planning, investing and risk management.
                                </span>
                                <div className="button__wrapper">
                                    <Link href="Login/signup" className="cmn--btn mt-4">Register</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="row gy-sm-5 gy-4 gx-xl-5 h-100 justify-content-between">
                                <div className="col-sm-6 col-lg-6 col-xl-6">
                                    <div className="feature__item__two style--two align-items-center">
                                        <div className="feature__item__two-thumb">
                                            <LiaCoinsSolid />
                                        </div>
                                        <div className="feature__item__two-content">
                                            <h3 className="title mb-2">
                                                Secure Payment
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-lg-6 col-xl-6">
                                    <div className="feature__item__two style--two align-items-center">
                                        <div className="feature__item__two-thumb">
                                            <LiaHeadphonesAltSolid />
                                        </div>
                                        <div className="feature__item__two-content">
                                            <h3 className="title mb-2">Unlimited Potential</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-lg-6 col-xl-6">
                                    <div className="feature__item__two style--two align-items-center">
                                        <div className="feature__item__two-thumb">
                                            <LiaShieldAltSolid />
                                        </div>
                                        <div className="feature__item__two-content">
                                            <h3 className="title mb-2">
                                                Easy Withdrawal
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-lg-6 col-xl-6">
                                    <div className="feature__item__two style--two align-items-center">
                                        <div className="feature__item__two-thumb">
                                            <LiaUserCheckSolid />
                                        </div>
                                        <div className="feature__item__two-content">
                                            <h3 className="title mb-2">
                                                Asset Management
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <LandingPlan />
            {/* <LandingInvestor /> */}
            <LandingFooter />
        </>
    )
}
