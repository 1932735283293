import React, { useState } from 'react'
import LandingHeader from '../LandingComponents/LandingHeader'
import bgBanner from '../Images/banner.png'
import { Link } from 'react-router-dom'
import bull from '../Images/bull.png'
import { FaDownload, FaQuoteLeft } from 'react-icons/fa'
import { RiDownloadLine } from "react-icons/ri";
import feature1 from '../Images/feature1.png'
import feature2 from '../Images/feature2.png'
import feature3 from '../Images/feature3.png'
import feature4 from '../Images/feature4.png'
import testimonial1 from '../Images/titem1.png'
import testimonial2 from '../Images/titem2.png'
import Slider from 'react-slick'
import LandingFooter from '../LandingComponents/LandingFooter'
import LandingPlan from '../LandingComponents/LandingPlan'
import LandingInvestor from '../LandingComponents/LandingInvestor'
import OurApp from '../LandingComponents/OurApp'
import CalculatePlan from '../LandingComponents/CalculatePlan'
import LandingFaq from '../LandingComponents/LandingFaq'
import partner1 from '../Images/p1.png'
import partner2 from '../Images/p2.png'
import partner3 from '../Images/p3.png'
import partner4 from '../Images/p4.png'
import partner5 from '../Images/p5.png'
import partner6 from '../Images/p6.png'
import partner7 from '../Images/p7.png'
import partner8 from '../Images/p8.png'
import partner9 from '../Images/p9.png'
import partner10 from '../Images/p10.png'
import partner11 from '../Images/p11.png'
import partner12 from '../Images/p12.png'
import partner13 from '../Images/p13.png'
import partner14 from '../Images/p14.png'
import partner15 from '../Images/p15.png'
import partner16 from '../Images/p16.png'
import partner17 from '../Images/p17.png'
import partner18 from '../Images/p18.png'
import partner19 from '../Images/p19.png'
import partner20 from '../Images/p20.png'
import partner21 from '../Images/p21.png'
import partner22 from '../Images/p22.png'
import partner23 from '../Images/p23.png'

export default function Home() {
    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToScroll: 1,
        pauseOnHover: false,
        arrows: false,  // Can add arrows if needed
    };


    const partnerSettings = {
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToScroll: 1,
        pauseOnHover: false,
        arrows: false,
    }

    return (
        <>
            <LandingHeader />
            {/* Banner Section Starts Here */}
            <section className="banner-section overflow-hidden" style={{ backgroundImage: `url(${bgBanner})`, backgroundSize: "cover", backgroundPosition: "center" }}>
                <div className="container">
                    <div className="banner__wrapper d-flex align-items-center justify-content-center">
                        <div className="banner__content">
                            <h1 className="title text-white">Vechain network Platform</h1>
                            <p className='text-white'>Vechain network Management is the Financial Planning and  company. As an independent wealth management firm, Vechain network Management helps individuals and families pursue their financial goals and lifestyle objectives through
                                comprehensive financial planning, investing and risk management.</p>
                            <Link to={'/sign-in'} className="cmn--btn">Get Started</Link>
                        </div>
                        {/* <div className="banner__thumb d-none d-lg-block">
                     <img src="assets/images/banner/inner-bg.webp" className="img-fluid" alt="banner">
                   <div className="shapes">
                        <img src="assets/images/banner/big-coin.png" alt="banner" className="shape shape1">
                        <img src="assets/images/banner/light.png" alt="banner" className="shape shape2">
                        <img src="assets/images/banner/sm-coin.png" alt="banner" className="shape shape3">
                        <img src="assets/images/banner/sm-coin.png" alt="banner" className="shape shape4">
                    </div> 
                </div> */}

                    </div>
                </div>
            </section>

            {/* whitepaper section */}

            <section className="padding-top padding-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <h2 className='heading-landing'>Vechain Whitepaper
                                <span className="gradient ms-4">3.0</span>
                            </h2>
                            <div className="button--wrapper">
                                <a href="https://vechain.org/wp-content/uploads/2023/10/vechain-whitepaper-3-0.pdf" target='_blank' className="cmn--btn4">Download whitepaper 3.0 <RiDownloadLine /></a>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <p>
                                The Web3 for Better whitepaper, co-authored with Boston Consulting Group, outlines our vision for a sustainable, circular economy, powered by Web3.
                            </p>
                            <p className='mt-3'>
                                It outlines our approach to building cross-industry blockchain business ecosystems that incentivize and empower stakeholders to create real world impact.
                            </p>
                        </div>
                    </div>
                </div>
            </section>


            {/* Why Choose Us Section Starts Here */}
            <section className="choose-us padding-top padding-bottom">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 d-none d-lg-block">
                            <div className="section__thumb rtl">
                                <img src={bull} alt="choose-us" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="choose__us__content">
                                <div className="section__header mb-0">
                                    <h2 className="section__header-title">Why Choose Our  Platform </h2>
                                </div>
                                <p>Whether you are just starting out and trying to accumulate wealth or are retired and in need of a consistent stream of income, Crest Wealth Management will develop a financial and Earning plan designed around your needs.</p>
                                <ul className="info__list d-flex flex-wrap mt-4">
                                    <li> LUCRATIVE BROKERS PROGRAM</li>
                                    <li> FUNDS WITHDRAWAL WITHIN 24 HOURS</li>
                                    <li> SUPERIOR FINANCIAL SERVICES</li>
                                    <li> SECURITY OF FUNDS</li>
                                    <li> INSTANT AND STABLE</li>
                                    <li> GLOBAL PRESENCE IN LEADING FINANCIAL HUBS</li>
                                </ul>
                                <div className="button__wrapper">
                                    <Link to={"sign-up"} target="_blank" className="cmn--btn">Register</Link>
                                    <Link to={'/contact'} className="cmn--btn2">
                                        <div className="gradient-border">
                                            Contact Us
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Plan Section Starts Here */}
            {/* <LandingPlan /> */}

            {/* Profit Calculation Section Starts Here */}
            {/* <CalculatePlan /> */}

            {/* Feature Section Starts Here */}
            <section className="feature-section padding-bottom overflow-hidden">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6 col-md-10">
                            <div className="section__header text-center">
                                <h2 className="section__header-title">Best of Vechain network Platform</h2>
                            </div>
                        </div>
                    </div>
                    <div className="feature__slider row">
                        <div className="single-slide col-md-3">
                            <div className="feature__item">
                                <div className="feature__item-icon">
                                    <img src={feature1} alt="feature" />
                                </div>
                                <div className="feature__item-content">
                                    <h4 className="title">Get More Profit</h4>
                                </div>
                            </div>
                        </div>
                        <div className="single-slide col-md-3">
                            <div className="feature__item">
                                <div className="feature__item-icon">
                                    <img src={feature2} alt="feature" />
                                </div>
                                <div className="feature__item-content">
                                    <h4 className="title">24/7 Support</h4>
                                </div>
                            </div>
                        </div>
                        <div className="single-slide col-md-3">
                            <div className="feature__item">
                                <div className="feature__item-icon">
                                    <img src={feature3} alt="feature" />
                                </div>
                                <div className="feature__item-content">
                                    <h4 className="title">Strong Protection</h4>
                                </div>
                            </div>
                        </div>
                        <div className="single-slide col-md-3">
                            <div className="feature__item">
                                <div className="feature__item-icon">
                                    <img src={feature4} alt="feature" />
                                </div>
                                <div className="feature__item-content">
                                    <h4 className="title">Relability</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Partners */}

            <section className="padding-top padding-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <h2 className='heading-landing'>Enterprise
                                <span className="gradient ms-4">Partners</span>
                            </h2>
                        </div>
                        <div className="col-md-6">
                            <p>
                                Real-world adoption of blockchain technology is what we do. Consumers across the world use VeChain powered solutions on a daily basis.
                            </p>
                        </div>
                    </div>
                    <Slider {...partnerSettings} className="testimonial__img__slider mt-4 mb-5">
                        <div className="single-slide px-1">
                            <div className="partner-thumb">
                                <img src={partner1} alt="testimonial" />
                            </div>
                        </div>
                        <div className="single-slide px-1">
                            <div className="partner-thumb">
                                <img src={partner2} alt="testimonial" />
                            </div>
                        </div>
                        <div className="single-slide px-1">
                            <div className="partner-thumb">
                                <img src={partner3} alt="testimonial" />
                            </div>
                        </div>
                        <div className="single-slide px-1">
                            <div className="partner-thumb">
                                <img src={partner4} alt="testimonial" />
                            </div>
                        </div>
                        <div className="single-slide px-1">
                            <div className="partner-thumb">
                                <img src={partner5} alt="testimonial" />
                            </div>
                        </div>
                        <div className="single-slide px-1">
                            <div className="partner-thumb">
                                <img src={partner6} alt="testimonial" />
                            </div>
                        </div>
                        <div className="single-slide px-1">
                            <div className="partner-thumb">
                                <img src={partner7} alt="testimonial" />
                            </div>
                        </div>
                        <div className="single-slide px-1">
                            <div className="partner-thumb">
                                <img src={partner8} alt="testimonial" />
                            </div>
                        </div>
                        <div className="single-slide px-1">
                            <div className="partner-thumb">
                                <img src={partner9} alt="testimonial" />
                            </div>
                        </div>
                        <div className="single-slide px-1">
                            <div className="partner-thumb">
                                <img src={partner10} alt="testimonial" />
                            </div>
                        </div>
                        <div className="single-slide px-1">
                            <div className="partner-thumb">
                                <img src={partner11} alt="testimonial" />
                            </div>
                        </div>
                        <div className="single-slide px-1">
                            <div className="partner-thumb">
                                <img src={partner12} alt="testimonial" />
                            </div>
                        </div>
                        <div className="single-slide px-1">
                            <div className="partner-thumb">
                                <img src={partner13} alt="testimonial" />
                            </div>
                        </div>
                        <div className="single-slide px-1">
                            <div className="partner-thumb">
                                <img src={partner14} alt="testimonial" />
                            </div>
                        </div>
                        <div className="single-slide px-1">
                            <div className="partner-thumb">
                                <img src={partner15} alt="testimonial" />
                            </div>
                        </div>
                        <div className="single-slide px-1">
                            <div className="partner-thumb">
                                <img src={partner16} alt="testimonial" />
                            </div>
                        </div>
                        <div className="single-slide px-1">
                            <div className="partner-thumb">
                                <img src={partner17} alt="testimonial" />
                            </div>
                        </div>
                        <div className="single-slide px-1">
                            <div className="partner-thumb">
                                <img src={partner18} alt="testimonial" />
                            </div>
                        </div>
                        <div className="single-slide px-1">
                            <div className="partner-thumb">
                                <img src={partner19} alt="testimonial" />
                            </div>
                        </div>
                        <div className="single-slide px-1">
                            <div className="partner-thumb">
                                <img src={partner20} alt="testimonial" />
                            </div>
                        </div>
                        <div className="single-slide px-1">
                            <div className="partner-thumb">
                                <img src={partner21} alt="testimonial" />
                            </div>
                        </div>
                        <div className="single-slide px-1">
                            <div className="partner-thumb">
                                <img src={partner22} alt="testimonial" />
                            </div>
                        </div>
                        <div className="single-slide px-1">
                            <div className="partner-thumb">
                                <img src={partner23} alt="testimonial" />
                            </div>
                        </div>
                    </Slider>
                </div>
            </section>
            {/* Investor Section Starts Here */}
            {/*<LandingInvestor />*/}

            {/* Download Section Starts Here */}
            {/* <OurApp /> */}

            {/* Faq Section Starts Here */}
            {/* <LandingFaq /> */}

            {/* Testimonial Section Starts Here */}
            <section className="testimonial-section padding-top padding-bottom">
                <div className="container">
                    <div className="row gy-4">
                        {/* Image Slider */}
                        <div className="col-lg-5">
                            <Slider {...settings} className="testimonial__img__slider">
                                <div className="single-slide">
                                    <div className="testimonial__thumb">
                                        <img src={testimonial1} alt="testimonial" />
                                    </div>
                                </div>
                                <div className="single-slide">
                                    <div className="testimonial__thumb">
                                        <img src={testimonial2} alt="testimonial" />
                                    </div>
                                </div>
                            </Slider>
                        </div>

                        {/* Content Slider */}
                        <div className="col-lg-7">
                            <Slider {...settings} className="testimonial__content__slider">
                                <div className="single-slide">
                                    <div className="testimonial__content">
                                        <div className="icon"><FaQuoteLeft style={{ color: "#82BE00" }} /></div>
                                        <p>Maecenas tempus tellus egcondi entuhoncus seuam semper libero sit amet adipiscing
                                            sque sesum.amuam nunc, blandit vel, luctus pvinar hendrerilorem. aecenas nec odio
                                        </p>
                                        <h3 className="name">Rasel Pranto</h3>
                                        <span className="designation">Front-end Web Developer</span>
                                    </div>
                                </div>
                                <div className="single-slide">
                                    <div className="testimonial__content">
                                        <div className="icon"><FaQuoteLeft style={{ color: "#82BE00" }} /></div>
                                        <p>Maecenas tempus tellus egcondi entuhoncus seuam semper libero sit amet adipiscing
                                            sque sesum.amuam nunc, blandit vel, luctus pvinar hendrerilorem. aecenas nec odio
                                        </p>
                                        <h3 className="name">Munna Ahmed</h3>
                                        <span className="designation">Front-end Web Developer</span>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
            </section>


            {/* Footer Section Starts Here */}
            <LandingFooter />
        </>
    )
}
