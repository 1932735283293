import CryptoJS from 'crypto-js';

// Your 32-byte (256-bit) key
const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

// Convert hex key to WordArray format expected by CryptoJS
const key = CryptoJS.enc.Hex.parse(SECRET_KEY);

// URL-safe Base64 encoding function
function urlSafeBase64Encode(data) {
    const base64String = CryptoJS.AES.encrypt(JSON.stringify(data), key, {
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
        iv: CryptoJS.enc.Hex.parse('00000000000000000000000000000000') // Use a secure IV in production
    }).toString();
    return base64String.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
}

// Encrypt data
function encryptData(data) {
    const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), key, {
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
        iv: CryptoJS.enc.Hex.parse('00000000000000000000000000000000') // Use a secure IV in production
    }).toString();

    // Convert to URL-safe Base64 format
    return ciphertext
}
export { encryptData, urlSafeBase64Encode }