import React, { useEffect, useState } from 'react'
import UserSidebar from '../Components/UserSidebar'
import { isMobile } from 'react-device-detect'
import axios from 'axios'
import Loader from '../Components/Loader'
import moment from 'moment'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { toast } from 'react-toastify'

export default function Profile() {

    const [profileData, setProfileData] = useState([])
    const [showLoader, setShowLoader] = useState(true)
    const [SEmail, setSEmail] = useState("")

    useEffect(() => {
        getProfileData()
    }, [])


    const getProfileData = () => {
        axios.post(process.env.REACT_APP_API_URL + '/user/get-user-data', { id: localStorage.getItem('mongoid') })
            .then((res) => {
                if (res.status == 200) {
                    setProfileData(res.data.data)
                    setSEmail(res.data.email)
                    setShowLoader(false)
                }
            })
    }

    const formik = useFormik({
        initialValues: {
            oldPassword: "",
            password: "",
            rePassword: "",
        },
        validationSchema: Yup.object({
            oldPassword: Yup.string()
                .required('Old Password is required'),
            password: Yup.string()
                .required('Password is required'),
            rePassword: Yup.string()
                .oneOf([Yup.ref('password'), null], 'Passwords must match')
                .required('Please confirm your password'),
            // termNCondition: Yup.boolean()
            //     .oneOf([true], 'You must accept the terms and conditions')
            //     .required('Please accept the terms and conditions'),
        }),
        onSubmit: values => {
            axios.post(process.env.REACT_APP_API_URL + '/user/change-password', { id: localStorage.getItem('mongoid'), pass: values.oldPassword, newPass: values.password })
                .then((res) => {
                    formik.resetForm()
                    if (res.data.status == 200) {
                        toast.success(res.data.message)
                    }
                    if (res.data.status == 401) {
                        toast.error(res.data.message)
                    }
                })
        }
    })

    return (
        <>
            {showLoader ? <Loader /> : ""}
            <UserSidebar />
            <div className={isMobile ? "MobileScreen" : "BigScreen"} id="page-container">
                <div className="container mt-3">
                    {/* <div className="top-nav">
                        <div>Personal Info</div>
                        <div>Change Password</div>
                    </div> */}
                    <div className="deposit-box mt-5">
                        <div className="box-heading">
                            <h4>Date of Joining : {moment(profileData?.date).format("DD-MM-YYYY")}</h4>
                        </div>
                        <hr />
                        <div className="row p-3">
                            <div className="col-md-6 mt-3 d-flex align-items-center">
                                <h6 className='w-20'>Sponser Id :</h6> <span className='text-white'>{profileData?.refTeam?.level1}</span>
                            </div>
                            {/*<div className="col-md-6 mt-3 d-flex align-items-center">
                                <h6 className='w-20'>Sponser Email :</h6> <span className='text-white'>{SEmail}</span>
                            </div>*/}
                            <div className="col-md-6 mt-3 d-flex align-items-center">
                                <h6 className='w-20'>User Id :</h6> <span className='text-white'>{localStorage.getItem('userid')}</span>
                            </div>
                            <div className="col-md-6 mt-3 d-flex align-items-center">
                                <h6 className='w-20'>User Email :</h6> <span className='text-white'>{localStorage.getItem('email')}</span>
                            </div>
                        </div>
                    </div>
                    <div className="deposit-box mt-5 w-100">
                        <div className="box-heading">
                            <h4>Change Password </h4>
                        </div>
                        <hr />
                        <form onSubmit={formik.handleSubmit}>
                            <div className="row p-3">
                                <div className="col-md-12">
                                    <label htmlFor="oldPassword">Old password : </label>
                                    <input type="password" className='form--control w-100 ps-3' name='oldPassword' id='oldPassword' value={formik.values.oldPassword} onChange={formik.handleChange} placeholder='Enter your old password' />
                                    {formik.errors.oldPassword && formik.submitCount > 0 ? (
                                        <div className="text-danger">{formik.errors.oldPassword}</div>
                                    ) : ""}
                                </div>
                                <div className="col-md-12 mt-3">
                                    <label htmlFor="password">New password : </label>
                                    <input type="password" className='form--control w-100 ps-3' name='password' id='password' value={formik.values.password} onChange={formik.handleChange} placeholder='Enter your new password' />
                                    {formik.errors.password && formik.submitCount > 0 ? (
                                        <div className="text-danger">{formik.errors.password}</div>
                                    ) : ""}
                                </div>
                                <div className="col-md-12 mt-3">
                                    <label htmlFor="rePassword">Re-password : </label>
                                    <input type="password" className='form--control w-100 ps-3' name='rePassword' id='rePassword' value={formik.values.rePassword} onChange={formik.handleChange} placeholder='Enter your password again' />
                                    {formik.errors.rePassword && formik.submitCount > 0 ? (
                                        <div className="text-danger">{formik.errors.rePassword}</div>
                                    ) : ""}
                                </div>
                                <div>
                                    <button className="btn btn-primary mt-3">change</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}
