import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './LandingPage/Home';
import { toast, ToastContainer } from 'react-toastify';
import About from './LandingPage/About';
import Earning from './LandingPage/Earning';
import Faqs from './LandingPage/Faqs';
import Contact from './LandingPage/Contact';
import Signin from './LandingPage/Signin';
import Signup from './LandingPage/Signup';
import Activate from './LandingPage/Activate';
import Dashboard from './UserPannel/Dashboard';
import ProtectedRouter from './UserPannel/ProtectedRouter';
import Package from './UserPannel/Package';
import Referral from './UserPannel/Referral';
import Profile from './UserPannel/Profile';
import Myteam from './UserPannel/Myteam';
import UserEarning from './UserPannel/UserEarning';
import useInactivityLogout from './UserPannel/useInactivityLogout';
import axios from 'axios';

function App() {

  axios.interceptors.request.use(
    (config) => {
      const sessionToken = localStorage.getItem('sessionToken');
      if (sessionToken) {
        config.headers['x-session-token'] = sessionToken;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // Response Interceptor: Handle session expiration or invalid token
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        // Clear session and redirect to login page
        toast.error('Session expired or logged in from another device.');
        localStorage.removeItem('sessionToken');
        localStorage.removeItem('expiresAt');
        window.location.href = '/'; // Redirect to login page
      }
      return Promise.reject(error);
    }
  );

  useInactivityLogout();

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='*' element={<Home />} />
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/earning' element={<Earning />} />
          <Route path='/faq' element={<Faqs />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/sign-in' element={<Signin />} />
          <Route path='/sign-up' element={<Signup />} />
          <Route path='/activate' element={<Activate />} />

          <Route path='/dashboard' element={<ProtectedRouter><Dashboard /></ProtectedRouter>} />
          <Route path='/referral-link' element={<ProtectedRouter><Referral /></ProtectedRouter>} />
          <Route path='/package' element={<ProtectedRouter><Package /></ProtectedRouter>} />
          <Route path='/profile' element={<ProtectedRouter><Profile /></ProtectedRouter>} />
          <Route path='/my-team' element={<ProtectedRouter><Myteam /></ProtectedRouter>} />
          <Route path='/user-earning' element={<ProtectedRouter><UserEarning /></ProtectedRouter>} />
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </>
  );
}

export default App;
