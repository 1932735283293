import React, { useEffect, useState } from 'react'
import UserSidebar from '../Components/UserSidebar'
import MUIDataTable from 'mui-datatables';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { isMobile } from 'react-device-detect'
import moment from 'moment';
import axios from 'axios';

export default function UserEarning() {
    const [tab, setTab] = useState(0)
    const [directIncome, setDirectIncome] = useState([])
    const [dailyIncome, setDailyIncome] = useState([])
    const [ROIonROI, setROIonROI] = useState([])
    const [rankIncome, setRankIncome] = useState([])

    useEffect(() => {
        getEarning()
    }, [])


    const getEarning = () => {
        axios.post(process.env.REACT_APP_API_URL + '/package/get-earning', { id: localStorage.getItem('mongoid') })
            .then((res) => {
                if (res.status == 200) {
                    setDirectIncome(res.data.directIncome)
                    setDailyIncome(res.data.dailyIncome)
                    setROIonROI(res.data.ROIonROI)
                    console.log(res.data)
                    setRankIncome(res.data.rankIncome)
                }
            })
    }

    const columns = [
        {
            name: 'date',
            label: 'Date',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => (
                    <p>{moment(value).format('DD-MM-YYYY')}</p>
                )
            }
        },
        {
            name: 'amount',
            label: 'Income($)',
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value) => (
                    <p>{Number(value).toFixed(3)}</p>
                )
            }
        },
        {
            name: 'statment',
            label: 'Statment',
            options: {
                filter: true,
                sort: false
            }
        }
    ];

    const getMuiTheme = () => createTheme({
        components: {
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        backgroundColor: '#28008C', // Background color for table cells
                        color: '#c5c7cc',
                        borderBottom: '2px solid #595a5d' // Custom border color
                    }
                }
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        backgroundColor: '#28008C', // Background color for the table container
                        color: '#c5c7cc'
                    }
                }
            }
        }
    });


    const options = {
        filter: false,         // Hide filter options
        download: false,       // Hide download button
        print: false,          // Hide print button
        viewColumns: false,    // Hide view columns button
        search: false,         // Hide search box
        selectableRows: 'none',
        responsive: 'standard',
        pagination: false,     // Hide pagination if you want
        elevation: 0           // Remove shadow
    };

    return (
        <>
            <UserSidebar />
            <div className={isMobile ? "MobileScreen" : "BigScreen"} id="page-container">
                <div className="container mt-3">
                    <div className="top-nav">
                        <div onClick={() => setTab(0)} className={tab == 0 ? "cursor-pointer top-nav-item top-active" : 'cursor-pointer top-nav-item'}>Direct Level Income</div>
                        <div onClick={() => setTab(3)} className={tab == 3 ? "cursor-pointer top-nav-item top-active" : 'cursor-pointer top-nav-item'}>Rank Income</div>
                        <div onClick={() => setTab(1)} className={tab == 1 ? "cursor-pointer top-nav-item top-active" : 'cursor-pointer top-nav-item'}>Daily Income</div>
                        <div onClick={() => setTab(2)} className={tab == 2 ? "cursor-pointer top-nav-item top-active" : 'cursor-pointer top-nav-item'}>ROI on ROI</div>
                    </div>
                    {tab == 0 ?
                        <div className="deposit-history mt-3">
                            <ThemeProvider theme={getMuiTheme()}>
                                <MUIDataTable
                                    title={"Direct Level Income"}
                                    data={directIncome}
                                    columns={columns}
                                    options={options}
                                />
                            </ThemeProvider>
                        </div>
                        : tab == 3 ?
                            <div className="deposit-history mt-3">
                                <ThemeProvider theme={getMuiTheme()}>
                                    <MUIDataTable
                                        title={"Rank Income"}
                                        data={rankIncome}
                                        columns={columns}
                                        options={options}
                                    />
                                </ThemeProvider>
                            </div>
                            : tab == 1 ?
                                <div className="deposit-history mt-3">
                                    <ThemeProvider theme={getMuiTheme()}>
                                        <MUIDataTable
                                            title={"Daily Income"}
                                            data={dailyIncome}
                                            columns={columns}
                                            options={options}
                                        />
                                    </ThemeProvider>
                                </div>
                                :
                                <div className="deposit-history mt-3">
                                    <ThemeProvider theme={getMuiTheme()}>
                                        <MUIDataTable
                                            title={"ROI on ROI"}
                                            data={ROIonROI}
                                            columns={columns}
                                            options={options}
                                        />
                                    </ThemeProvider>
                                </div>
                    }
                </div>
            </div>
        </>
    )
}
