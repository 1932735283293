import React from 'react'
import innerBg from '../Images/banner.png'
import { Link } from 'react-router-dom'

export default function LandingBanner({ page, header }) {
    return (
        <div className="inner-banner overflow-hidden mb-5" style={{ backgroundImage: `url(${innerBg})`, backgroundSize: "cover", backgroundPosition: "center" }}>
            <div className="container">
                <div className="inner__banner__content text-center">
                    <h2 className="title text-white">{header}</h2>
                    <ul className="breadcums d-flex flex-wrap justify-content-center">
                        <li><Link to={'/'}>Home</Link>//</li>
                        <li>{page}</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
