import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { DAppKitProvider } from '@vechain/dapp-kit-react';

const root = ReactDOM.createRoot(document.getElementById('root'));

const walletConnectOptions = {
  // Create your project here: https://cloud.walletconnect.com/sign-up
  projectId: '6ee3beefcdad81d22490fdd777d4a8f2',
  metadata: {
    name: 'Demo',
    description: 'Demo for learning purpose',
    // Your app URL
    url: window.location.origin,
    // Your app Icon
    icons: ['https://cdn-icons-png.freepik.com/512/8757/8757976.png'],
  },
};

root.render(
  // <React.StrictMode>
  <DAppKitProvider
    // REQUIRED: The URL of the node you want to connect to
    nodeUrl={'https://mainnet.vechain.org/'}
    // OPTIONAL: Required if you're not connecting to the main net
    // genesis={'test'}
    // OPTIONAL: Whether or not to persist state in local storage (account, wallet source)
    usePersistence={true}
    // OPTIONAL: Options to enable wallet connect
    walletConnectOptions={walletConnectOptions}
    // OPTIONAL: A log level for console logs
    logLevel="DEBUG"
    // OPTIONAL: theme mode 'LIGHT' or 'DARK'
    themeMode='LIGHT'
    // OPTIONAL: app current language
    language="en"
    modalParent={document.body}
    // OPTIONAL: handle source click to customise wallet connect
    // OPTIONAL: every wallet has a connection certificate, but wallet connect doesn't connect with a certificate, it uses a session; if required, with this option, we will force the user to sign a certificate after he finishes the connection with wallet connect
    requireCertificate={false}
  >
    <App />
  </DAppKitProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
