import React, { useEffect } from 'react'
import Loader from '../Components/Loader'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { toast } from 'react-toastify'

export default function Activate() {
    const navigate = useNavigate()
    useEffect(() => {
        const url = new URL(window.location.href)
        const params = new URLSearchParams(url.search)
        const id = params.get('id')
        axios.post(process.env.REACT_APP_API_URL + '/activate-account', { id: id })
            .then((res) => {
                if (res.status == 200) {
                    toast.success(res.data.message)
                    navigate('/sign-in')
                }
            })
    }, [])

    return (
        <>
            <Loader />
        </>
    )
}
