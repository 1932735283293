import React from 'react'
import UserSidebar from '../Components/UserSidebar'
import { isMobile } from 'react-device-detect'
import { toast } from 'react-toastify';

export default function Referral() {

    const copy = (text) => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(text)
                .then(() => {
                    toast.success("Link copied successfully.")
                    // You can also display a success message here if needed
                })
                .catch((err) => {
                    console.error('Failed to copy text: ', err);
                });
        } else {
            // Fallback for older browsers
            let textarea = document.createElement('textarea');
            textarea.value = text;
            textarea.style.position = 'fixed'; // Prevent scrolling to the bottom
            document.body.appendChild(textarea);
            textarea.focus();
            textarea.select();
            try {
                document.execCommand('copy');
                // console.log('Text copied to clipboard (fallback):', text);
                toast.success("Link copied successfully.")
            } catch (err) {
                console.error('Fallback: Oops, unable to copy', err);
            }
            document.body.removeChild(textarea);
        }

    }
    return (
        <>
            <UserSidebar />
            <div className={isMobile ? "MobileScreen" : "BigScreen"} id="page-container">
                <h2 className='mt-3 ms-3'>Referral Link</h2>
                <div className="d-flex ms-3 align-items-center">
                    <button className="btn btn-primary" onClick={() => copy(window.location.origin + "/sign-up?invite=" + localStorage.getItem('userid'))}>Copy</button>
                    <p className='ms-3 text-white'>{window.location.origin + "/sign-up?invite=" + localStorage.getItem('userid')}</p>
                </div>
            </div>
        </>
    )
}
