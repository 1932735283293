import React, { useState } from 'react'
import LandingHeader from '../LandingComponents/LandingHeader'
import LandingBanner from '../LandingComponents/LandingBanner'
import LandingFooter from '../LandingComponents/LandingFooter'
import { LiaEnvelope, LiaTelegram, LiaWhatsapp } from 'react-icons/lia'
import { toast } from 'react-toastify'
import axios from 'axios'

export default function Contact() {
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [message, setMessage] = useState("")

    const sendMessage = () => {
        if (name && email && message) {
            axios.post(process.env.REACT_APP_API_URL + '/user/send-message', { name, email, message })
                .then((res) => {
                    if (res.status == 200) {
                        setName("")
                        setMessage("")
                        setEmail("")

                        toast.success("Message sent you will get reply soon.")
                    }
                })
        } else {
            toast.error("Please fill all details.")
        }
    }

    return (
        <>
            <LandingHeader />
            <LandingBanner page={"Contact"} header={"Get in Touch With us"} />
            <div className="contact-section">
                <div className="container">
                    <div className="row padding-top padding-bottom gy-4 justify-content-center">
                        <div className="col-lg-4 col-md-6 col-sm-10">
                            <div className="contact__info__item plan__item">
                                <div className="icon">
                                    <LiaTelegram />
                                </div>
                                <div className="content">
                                    <h3 className="title">Telegram</h3>
                                    <p>@Vechainnetwork</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-10">
                            <div className="contact__info__item plan__item">
                                <div className="icon">
                                    <LiaEnvelope />
                                </div>
                                <div className="content">
                                    <h3 className="title">Email Address</h3>
                                    <ul className="contacts">
                                        <li>
                                            <a href="mail:support@Vechainnetwork.com">support@Vechainnetwork.com</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-10">
                            <div className="contact__info__item plan__item">
                                <div className="icon">
                                    <LiaWhatsapp />
                                </div>
                                <div className="content">
                                    <h3 className="title">Whatsapp</h3>
                                    <ul className="contacts">
                                        <li><a href="tel:+100000000000">+10-000 000 0000</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="position-relative map__area">
                    <div className="container padding-top padding-bottom contact__area">
                        <div className="contact__form__wrapper mx-auto me-lg-0">
                            <h3 className="title text-white">Send Your Messages</h3>
                            <form className="contact__form form" id="contact_form_submit" >
                                <div className="form-group">
                                    <input type="text" className="form-control form--control" placeholder="Full Name" name="contact_name" id="contact_name" value={name} onChange={(e) => setName(e.target.value)} />
                                </div>
                                <div className="form-group">
                                    <input type="email" className="form-control form--control" name="contact_email" placeholder="Email" id="contact_email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                </div>
                                <div className="form-group">
                                    <textarea className="form-control form--control" name="contact_message" placeholder="Write Your Messages" id="contact_message" value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
                                </div>
                                <button type="button" className="cmn--btn btn" onClick={sendMessage}>Send Message</button>
                            </form>
                        </div>
                    </div>
                    <div className="map__wrapper">
                        <div className="overlay01"></div>
                        <iframe className="map" src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d4340.214604426607!2d90.39243230460072!3d23.830298940050135!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sbd!4v1638968781774!5m2!1sen!2sbd" style={{ border: 0 }} allowfullscreen=""
                            loading="lazy"></iframe>
                    </div>
                </div>
            </div>
            <LandingFooter full={true} />
        </>
    )
}
