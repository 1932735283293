import React from 'react'
import { FaFacebook, FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa'
import footerbg from '../Images/footer.png'
export default function LandingFooter({ full }) {
    return (
        <>
            <footer className="footer-section section-bg" style={{ backgroundImage: `url(${footerbg})`, backgroundSize: "cover", backgroundColor: full ? "#28008c" : "#e8eced", color: "#fff" }}>
                <div className="footer-top padding-top pb-5 border-bottom border--white">
                    <div className="container">
                        <div className="row gy-5 justify-content-md-between">
                            <div className="col-xl-6 col-lg-6 col-sm-6">
                                <div className="footer__widget">
                                    <h3 className="widget-title">About Company</h3>
                                    <p>Vechain network Management is the Financial Planning and Earning company. As an independent
                                        wealth management firm, Vechain network Management helps individuals and families pursue
                                        their financial goals and lifestyle objectives
                                        through comprehensive financial planning, investing and risk management.</p>
                                    <ul className="social-links">
                                        <li>
                                            <a href="https://www.facebook.com/crestwealthm" target="_blank"><FaFacebook /></a>
                                        </li>
                                        <li>
                                            <a href="https://www.instagram.com/crestwealthm" target="_blank"><FaInstagram /></a>
                                        </li>
                                        <li>
                                            <a href="https://www.twitter.com/crestwealthm" target="_blank"><FaTwitter /></a>
                                        </li>
                                        <li>
                                            <a href="https://youtube.com/@CrestWealthm" target="_blank"><FaYoutube /></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-3 col-sm-6">
                                <div className="footer__widget">
                                    <h3 className="widget-title">Useful Links</h3>
                                    <ul className="footer-links">
                                        <li><a href="#">Privacy Policy</a></li>
                                        <li><a href="#">Cookie Policy</a></li>
                                        <li><a href="#">Terms & Conditions</a></li>
                                        <li><a href="#">Website Disclaimer</a></li>
                                        <li><a href="#">Risk Warning</a></li>

                                    </ul>
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-3 col-sm-6">
                                <div className="footer__widget">
                                    <h3 className="widget-title">Company</h3>
                                    <ul className="footer-links">
                                        <li><a href="index.html">Home</a></li>
                                        <li><a href="about.html">About</a></li>
                                        <li><a href="plan.html">Earning</a></li>
                                        <li><a href="faq.html">FAQs</a></li>
                                        <li><a href="contact.html">Contact</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="container">
                        <p className="copyright text-center py-3">
                            Copyright &copy; 2024 <a href="#"><span style={{ color: "#82BE00" }}>Vechain network Management </span> </a>
                            All Rights Reserved.
                        </p>
                    </div>
                </div>
            </footer>
        </>
    )
}
