import React, { useEffect, useState } from 'react'
import UserSidebar from '../Components/UserSidebar'
import { isMobile } from 'react-device-detect'
import MUIDataTable from 'mui-datatables';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import moment from 'moment';
import axios from 'axios';
import Loader from '../Components/Loader';
import { FaStar } from 'react-icons/fa';

export default function Myteam() {

    const [selfTeam, setSelfTeam] = useState([])
    const [totalTeam, setTotalTeam] = useState([])
    const [showLoader, setShowLoader] = useState(true)
    const [tab, setTab] = useState(0)

    useEffect(() => {
        getUserData()
    }, [])


    const getUserData = () => {
        axios.post(process.env.REACT_APP_API_URL + '/user/get-team', { id: localStorage.getItem('mongoid') })
            .then((res) => {
                setShowLoader(false)
                if (res.status == 200) {
                    let data = res.data
                    console.log(data)
                    setSelfTeam(data.directFriends)
                    setTotalTeam(data.combinedTeam)
                }
            })
    }

    const getMuiTheme = () => createTheme({
        components: {
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        backgroundColor: '#28008C', // Background color for table cells
                        color: '#c5c7cc',
                        borderBottom: '2px solid #595a5d' // Custom border color
                    }
                }
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        backgroundColor: '#28008C', // Background color for the table container
                        color: '#c5c7cc'
                    }
                }
            }
        }
    });

    const Scolumns = [
        {
            name: 'date',
            label: 'Date',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => (
                    <p>{moment(value).format('DD-MM-YYYY')}</p>
                )
            }
        },
        {
            name: 'friendId',
            label: 'ID',
            options: {
                filter: true,
                sort: false
            }
        },
        {
            name: 'email',
            label: 'Email',
            options: {
                filter: true,
                sort: false
            }
        },
        {
            name: 'income',
            label: 'Income($)',
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value) => (
                    <p>{Number(value).toFixed(2)}</p>
                )
            }
        },
        {
            name: 'pkg',
            label: 'Package($)',
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value) => (
                    <p>{Number(value).toFixed(2)}</p>
                )
            }
        },
        {
            name: 'rank',
            label: 'Rank',
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value) => (
                    <p>{value} <FaStar /></p>
                )
            }
        },
    ];
    const Tcolumns = [
        {
            name: 'date',
            label: 'Date',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => (
                    <p>{moment(value).format('DD-MM-YYYY')}</p>
                )
            }
        },
        {
            name: 'friendId',
            label: 'ID',
            options: {
                filter: true,
                sort: false
            }
        },
        {
            name: 'email',
            label: 'Email',
            options: {
                filter: true,
                sort: false
            }
        },
        {
            name: 'income',
            label: 'Income($)',
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value) => (
                    <p>{Number(value).toFixed(2)}</p>
                )
            }
        },
        {
            name: 'level',
            label: 'Level',
            options: {
                filter: true,
                sort: false
            }
        }
    ];

    // Options to hide various features from the table
    const options = {
        filter: false,         // Hide filter options
        download: false,       // Hide download button
        print: false,          // Hide print button
        viewColumns: false,    // Hide view columns button
        search: false,         // Hide search box
        selectableRows: 'none',
        responsive: 'standard',
        pagination: false,     // Hide pagination if you want
        elevation: 0           // Remove shadow
    };

    return (
        <>
            {showLoader ? <Loader /> : ""}
            <UserSidebar />
            <div className={isMobile ? "MobileScreen" : "BigScreen"} id="page-container">
                <div className="container mt-3">
                    <div className="top-nav">
                        <div onClick={() => setTab(0)} className={tab == 0 ? "cursor-pointer top-nav-item top-active" : 'cursor-pointer top-nav-item'}>Self Team</div>
                        <div onClick={() => setTab(1)} className={tab == 1 ? "cursor-pointer top-nav-item top-active" : 'cursor-pointer top-nav-item'}>Total Team</div>
                    </div>
                    {tab == 0 ?
                        <div className="deposit-history mt-3">
                            <ThemeProvider theme={getMuiTheme()}>
                                <MUIDataTable
                                    title={"Self team"}
                                    data={selfTeam}
                                    columns={Scolumns}
                                    options={options}
                                />
                            </ThemeProvider>
                        </div>
                        :
                        <div className="deposit-history mt-3">
                            <ThemeProvider theme={getMuiTheme()}>
                                <MUIDataTable
                                    title={"Total team"}
                                    data={totalTeam}
                                    columns={Tcolumns}
                                    options={options}
                                />
                            </ThemeProvider>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}
