import React, { useState } from 'react'
import logo from '../Images/veworld-logo.png'
import { Link } from 'react-router-dom'


export default function LandingHeader() {

    const [Toggle, setToggle] = useState(false)

    const handleToggle = () => {
        const toggle = document.getElementById("toggle-button")
        const overlay = document.getElementById("overlay")
        const menu = document.getElementById('menu')
        if (Toggle) {
            menu.classList.remove("active")
            toggle.classList.remove("active")
            overlay.classList.remove("active")
            setToggle(false)
        } else {
            menu.classList.add("active")
            toggle.classList.add("active")
            overlay.classList.add("active")
            setToggle(true)
        }
    }

    return (
        <>
            <div className="overlay" id='overlay' onClick={handleToggle}></div>
            <div className="header">
                <div className="header-bottom">
                    <div className="container">
                        <div className="header-bottom-area">
                            <div className="logo">
                                {/* <Link to={'/'}><h3>LOGO</h3></Link> */}
                                <Link to={'/'}><img src={logo} alt="logo" /></Link>
                            </div>
                            <ul className="menu" id='menu'>
                                <li>
                                    <Link to={'/'}>Home</Link>
                                </li>
                                <li>
                                    <Link to={'/about'}>About</Link>
                                </li>
                                <li>
                                    <Link to={'/earning'}>Earning</Link>
                                </li>
                                <li>
                                    {/* <Link to={'/faq'}>FAQs</Link> */}
                                </li>
                                <li>
                                    <Link to={'/contact'}>Contact</Link>
                                </li>


                                <li className="p-0 d-lg-none mt-3 mt-lg-0">
                                    <div className="button__wrapper">
                                        <Link className="cmn--btn" to={'/sign-up'}>Register</Link>
                                        <Link className="cmn--btn" to={'/sign-in'}>Login</Link>
                                    </div>
                                </li>
                            </ul>
                            {/* <!-- Menu End --> */}

                            <div className="button__wrapper d-none d-lg-block">
                                <Link className="cmn--btn" to={'/sign-up'}>Register</Link>
                                <Link className="cmn--btn" to={'/sign-in'}>Login</Link>
                            </div>


                            <div className="header-trigger-wrapper d-flex d-lg-none align-items-center">
                                <div className="mobile-nav-right d-flex align-items-center"></div>
                                <Link className="search--btn me-4 text--base"><i className="fas fa-search"></i></Link>
                                <div className="header-trigger d-block d--none" onClick={handleToggle} id='toggle-button'>
                                    <span></span>
                                </div>
                            </div>
                            {/* <!-- Trigger End--> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
