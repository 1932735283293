import React from 'react'
import { Navigate } from 'react-router-dom'

export default function ProtectedRouter(props) {
    const retunrCondtion = () => {
        if (window.location.hostname != 'localhost') {
            if (localStorage.getItem('email')) {
                return true
            } else {
                return false
            }
        } else {
            return true
        }
    }
    return (
        <>
            {retunrCondtion() ? props.children : <Navigate to={'/'} />}
        </>
    )
}
