import React, { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { FaAddressCard, FaBars, FaUsers } from 'react-icons/fa'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar'
import { IoIosArrowDropleftCircle, IoIosArrowDroprightCircle, IoIosLogOut } from 'react-icons/io';
import { MdAttachMoney, MdDashboard, MdWorkHistory } from "react-icons/md";
import { GiMoneyStack } from "react-icons/gi";
import { PiUsersFour } from "react-icons/pi";
import { useWallet, WalletButton } from '@vechain/dapp-kit-react'
import logo from '../Images/veworld-logo.png'
import axios from 'axios'

export default function UserSidebar() {
    const [collapsed, setCollapsed] = useState(isMobile ? true : false)
    const [toggled, setToggled] = useState(false)
    const navigate = useNavigate()
    const { account } = useWallet()
    const location = useLocation()

    useEffect(() => {
        if (account) {
            axios.post(process.env.REACT_APP_API_URL + '/user/change-wallet', { userid: localStorage.getItem('mongoid'), wallet: account })
        }
    }, [account])


    const logout = () => {
        localStorage.removeItem('sessionToken');
        localStorage.removeItem('expiresAt');
        localStorage.removeItem("mongoid")
        localStorage.removeItem("email")
        localStorage.removeItem("userid")
        navigate('/');
    };

    const handleCollapsedChange = () => {
        if (collapsed) {
            if (!isMobile) {
                document.getElementById('page-container').setAttribute('class', 'BigScreen')
            }
        } else {
            if (!isMobile) {
                document.getElementById('page-container').setAttribute('class', 'SmallScreen')
            }
        }
        setCollapsed(!collapsed);

    };
    return (
        <>
            <Sidebar
                // backgroundColor="#28008C"
                backgroundColor='transparent'
                className={`app ${toggled ? "toggle" : ""}`}
                style={{
                    height: "100%", position: "fixed", border: "none", zIndex: 51, boxShadow: "0px 5px 10px 0px rgba(0, 0, 0, 0.2)", color: "#fff", background: "linear-gradient(135deg, #28008C 13%, #00BED7 60%, #82BE00 100%)"

                }}
                collapsed={collapsed}
                onBackdropClick={() => setToggled(false)}
                toggled={toggled}
                breakPoint="lg"
            >
                <Menu iconshape="square">
                    <div>
                        {collapsed ?
                            <MenuItem
                                icon={<IoIosArrowDroprightCircle />}
                                onClick={handleCollapsedChange}
                                className='py-2'
                            ></MenuItem>
                            : <MenuItem
                                suffix={<IoIosArrowDropleftCircle />}
                                onClick={handleCollapsedChange}
                                className='py-2'
                            >
                                <div
                                    style={{
                                        padding: "7px",
                                        color: "#fff"
                                    }}
                                >
                                    {/* <p>Company Name</p> */}
                                    <img className='img-fluid mt-3' src={logo} alt='logo'></img>
                                </div>
                            </MenuItem>}
                    </div>

                    <hr className='m-0' />
                </Menu>
                <Menu>
                    <MenuItem className={location.pathname == '/dashboard' ? "ActiveSidebar" : ""} icon={<MdDashboard />} component={<Link to={'/dashboard'}></Link>}>Dashboard</MenuItem>
                    {/* <MenuItem className={location.pathname == '/referral-link' ? "ActiveSidebar" : ""} icon={<FaUsers />} component={<Link to={'/referral-link'}></Link>}>My Referral Link</MenuItem> */}
                    <MenuItem className={location.pathname == '/profile' ? "ActiveSidebar" : ""} icon={<FaAddressCard />} component={<Link to={'/profile'}></Link>}>Profile</MenuItem>
                    <MenuItem className={location.pathname == '/package' ? "ActiveSidebar" : ""} icon={<MdAttachMoney style={{ fontSize: 22 }} />} component={<Link to={'/package'}></Link>}>Package</MenuItem>
                    {/* <MenuItem className={location.pathname == '/activity' ? "ActiveSidebar" : ""} icon={<MdWorkHistory style={{ fontSize: 22 }} />} component={<Link to={'/activity'}></Link>}>Activity</MenuItem> */}
                    <MenuItem className={location.pathname == '/user-earning' ? "ActiveSidebar" : ""} icon={<GiMoneyStack style={{ fontSize: 22 }} />} component={<Link to={'/user-earning'}></Link>}>Earning</MenuItem>
                    <MenuItem className={location.pathname == '/my-team' ? "ActiveSidebar" : ""} icon={<PiUsersFour />} component={<Link to={'/my-team'}></Link>}>My Team</MenuItem>
                    <MenuItem icon={<IoIosLogOut style={{ fontSize: 22 }} />} component={<a onClick={logout}></a>}>Logout</MenuItem>


                </Menu>
            </Sidebar >
            <div className="HeaderClass" style={{ position: 'fixed', padding: "15px" }}>
                <div className="w-100 d-flex justify-content-between">
                    <button className='btn p-0' onClick={() => setToggled(!toggled)}>
                        <FaBars style={{ color: '#fff', fontSize: "20px" }} />
                    </button>
                    <WalletButton />
                </div>

            </div>
        </>
    )
}
