import React from 'react'
import { lineSpinner } from 'ldrs'

lineSpinner.register()

// Default values shown

export default function Loader() {
    return (
        <div className='loader'>

            <l-line-spinner
                size="50"
                stroke="3"
                speed="1"
                color="white"
            ></l-line-spinner>
        </div>
    )
}
