import React from 'react'
import LandingHeader from '../LandingComponents/LandingHeader'
import LandingBanner from '../LandingComponents/LandingBanner'
import LandingFaq from '../LandingComponents/LandingFaq'
import LandingFooter from '../LandingComponents/LandingFooter'

export default function Faqs() {
    return (
        <>
            <LandingHeader />
            <LandingBanner page={"FAQs"} header={"FAQs"} />
            <LandingFaq />
            <LandingFooter />
        </>
    )
}
