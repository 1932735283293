import React from 'react'
import LandingHeader from '../LandingComponents/LandingHeader'
import LandingBanner from '../LandingComponents/LandingBanner'
import LandingPlan from '../LandingComponents/LandingPlan'
import CalculatePlan from '../LandingComponents/CalculatePlan'
import LandingFooter from '../LandingComponents/LandingFooter'

export default function Earning() {
    return (
        <>
            <LandingHeader />
            <LandingBanner page={"Earning Plan"} header={"Earning Plan"} />
            <LandingPlan />
            {/* <CalculatePlan /> */}
            <LandingFooter />
        </>
    )
}
