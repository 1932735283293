import { useEffect } from 'react';
import { toast } from 'react-toastify';

const useInactivityLogout = () => {
    useEffect(() => {
        let timeout;

        const resetTimer = () => {
            clearTimeout(timeout);
            timeout = setTimeout(logout, 5 * 60 * 1000); // 5 minutes
        };

        const logout = () => {
            localStorage.removeItem('sessionToken');
            localStorage.removeItem('expiresAt');
            localStorage.removeItem("mongoid")
            localStorage.removeItem("email")
            localStorage.removeItem("userid")
            toast.error('Logged out due to inactivity');
            window.location.href = '/';
        };

        window.addEventListener('mousemove', resetTimer);
        window.addEventListener('keypress', resetTimer);

        resetTimer(); // Start timer on load

        return () => {
            window.removeEventListener('mousemove', resetTimer);
            window.removeEventListener('keypress', resetTimer);
        };
    }, []);
};

export default useInactivityLogout;