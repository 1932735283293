import React from 'react'

export default function LandingPlan() {
    return (
        <section className="plan-section padding-bottom">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-10">
                        <div className="section__header text-center">
                            <h2 className="section__header-title">Our  Plan</h2>
                        </div>
                    </div>
                </div>
                <div className="row g-4 justify-content-center">
                    <div className="col-lg-4 col-xl-4 col-md-6 col-sm-6">
                        <div className="plan__item">
                            <div className="plan__item-header">
                                <h2 className="plan-parcent">0.3%</h2>
                                <p className="plan-parcent-info">In multiple of $50</p>
                            </div>
                            <div className="plan__item-body">
                                <ul className="plan__info">
                                    <li>
                                        <span className="title">Min :</span>
                                        <span className="value">50 USD</span>
                                    </li>
                                    <li>
                                        <span className="title">Max :</span>
                                        <span className="value">1000 USD</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="plan__item-footer">
                                <p className="footer-info"> SILVER</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-xl-4 col-md-6 col-sm-6">
                        <div className="plan__item">
                            <div className="plan__item-header">
                                <h2 className="plan-parcent">0.4%</h2>
                                <p className="plan-parcent-info">In multiple of $50</p>
                            </div>
                            <div className="plan__item-body">
                                <ul className="plan__info">
                                    <li>
                                        <span className="title">Min :</span>
                                        <span className="value">1000 USD</span>
                                    </li>
                                    <li>
                                        <span className="title">Max :</span>
                                        <span className="value">No limit</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="plan__item-footer">
                                <p className="footer-info"> GOLD</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-xl-4 col-md-6 col-sm-6">
                        <div className="plan__item__earn">
                            <div className="plan__item-header">
                                <h3 className="plan-parcent-heading">Earn USD</h3>
                                <p className="plan-parcent-info">Refer Friends and Earn on Their Deposits!</p>
                            </div>
                            <div className="plan__item-body">
                                <ul className="plan__info referal_info">
                                    <li>
                                        <span className="title">Level 1 :</span>
                                        <span className="value">5%</span>
                                    </li>
                                    <li>
                                        <span className="title">Level 2 :</span>
                                        <span className="value">2%</span>
                                    </li>
                                    <li>
                                        <span className="title">Level 3 :</span>
                                        <span className="value">1%</span>
                                    </li>
                                    <li>
                                        <span className="title">Level 4 :</span>
                                        <span className="value">1%</span>
                                    </li>
                                    <li>
                                        <span className="title">Level 5 :</span>
                                        <span className="value">1%</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="plan__item-footer">
                                <p className="footer-info-earn"> Rewards are granted based on the number of active friends you refer.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
